import {useEffect} from "react";

const useScrollLock = shouldLock => {
  useEffect(() => {
    if (shouldLock) {
      window.document.body.parentElement.classList.add("!overflow-hidden");
    } else {
      window.document.body.parentElement.classList.remove("!overflow-hidden");
    }
    return () => {
      window.document.body.parentElement.classList.remove("!overflow-hidden");
    };
  }, [shouldLock]);
};

export default useScrollLock;
