import {useEffect} from "react";
import {overlay} from "@/store/Overlay";

export default (open, overHeader) => {
  useEffect(() => {
    if (open) {
      overlay.open(overHeader);
    } else {
      overlay.close();
    }

    return () => {
      overlay.close();
    };
  }, [open, overHeader]);
};
